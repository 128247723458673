<template>
  <ConfirmDialog
    :value.sync="show"
    title="Sparuj wpis"
    maxWidth="900px"
    positiveButtonText="Zapisz"
    :positiveAction="save"
    :loading="loading"
    dontHideOnPositiveAction
    persistent
    showCloseBtn
  >
    <v-layout column slot="content">
      <DefaultLabel class="mx-4">{{ predefined.title }}</DefaultLabel>
      <DefaultSubText color="secondary" class="mx-4 pre-wrap">{{
        predefined.text
      }}</DefaultSubText>
      <v-divider class="mx-4 mt-4"></v-divider>
      <DefaultLabel class="mx-4 mt-4 mb-1">Aktualnie sparowane:</DefaultLabel>
      <PredefinedPairDelegate
        class="mx-4"
        v-for="pair in paired"
        :key="pair.altId"
        :item="pair"
        :paired.sync="paired"
        hideOpenIcon
        deleteBtn
      >
      </PredefinedPairDelegate>
      <v-divider class="mx-4 mt-4"></v-divider>
      <v-layout column>
        <PredefinedCategoryDelegate
          v-for="(field, index) in fields"
          :key="field.altId"
          :field.sync="fields[index]"
          :paired.sync="paired"
          :cardType="cardType"
        >
        </PredefinedCategoryDelegate>
      </v-layout>
    </v-layout>
    <v-layout column slot="footer">
      <v-flex v-if="messageType != 0 && showMessage" shrink>
        <v-fade-transition>
          <Message
            :type="messageType"
            :text="message"
            @close="hideMessage"
          ></Message>
        </v-fade-transition>
      </v-flex>
      <v-layout :class="messageType != 0 && showMessage ? 'mt-2' : ''">
        <v-spacer></v-spacer>
        <DefaultButton @click.stop="hidePopup" class="my-0"
          >Anuluj</DefaultButton
        >
        <DefaultButton
          @click.stop="save"
          :loading="loading"
          class="my-0"
          primary
          >Zapisz</DefaultButton
        >
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>

import CRUDMixin from "@/mixins/CRUDMixin";
import PredefinedService from "@/services/predefined.service";
import SettingsService from "@/services/settings.service";
import { MEDICAL_CODES_FIELDS } from "@/constants/patientcard/fields/types";
import _ from "lodash";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    predefined: {},
    cardType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      codes: [],
      open: [],
      fields: [],
      paired: [],
      pagination: {
        rowsPerPage: -1,
        page: 1,
        min: 1,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    FavoriteMedicalCodeDelegate: () =>
      import("@/components/delegates/FavoriteMedicalCodeDelegate"),
    FavoriteMedicalCodesFilter: () =>
      import("@/components/lists/FavoriteMedicalCodesFilter"),
    PredefinedPairDelegate: () =>
      import("@/components/delegates/predefined/PredefinedPairDelegate"),
    PredefinedCategoryDelegate: () =>
      import("@/components/delegates/predefined/PredefinedCategoryDelegate"),
  },
  methods: {
    hidePopup() {
      this.show = false;
    },
    fetchCategories() {
      this.beforeRequest();
      return SettingsService.getPatientCardSettings(this.cardType)
        .then((reply) => {
          this.fields = [];
          for (
            let index = 0;
            index < reply.appointment_history_fields.length;
            index++
          ) {
            let element = reply.appointment_history_fields[index];
            if (
              MEDICAL_CODES_FIELDS.includes(element.type) ||
              element.predefined_records_enabled
            ) {
              this.$set(element, "children", []);
              this.$set(element, "checkable", false);
              this.$set(element, "altId", element.name);
              this.fields.push(element);
            }
          }
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.reply);
        });
    },
    fetchPairs() {
      this.beforeRequest();
      return PredefinedService.getPredefinedPairs(this.predefined.id)
        .then((reply) => {
          this.paired = reply.fields;
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.reply);
        });
    },
    save() {
      this.beforeRequest();
      PredefinedService.savePredefinedPairs(this.predefined.id, {
        id: this.predefined.id,
        fields: this.paired,
      })
        .then(() => {
          this.reset();
          this.$emit("success", "Wsystkie zmiany zapisano pomyślnie.");
          this.show = false;
        })
        .catch((error) => {
          console.log("ERROR", error, error.reply);
          this.handleError("Coś poszło nie tak.");
        });
    },
  },
  mounted() {
    this.fetchCategories().then(() => this.fetchPairs(this.predefined.id));
  },
};
</script>

<style scoped lang="css">
.pre-wrap {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>